import React from 'react';
import { DownloadIcon, PrintIcon } from '../Icons';
import { gql, useMutation } from '@apollo/client';
import EulaText from './EulaText';
import LoadingIndicator from '../LoadingIndicator';
import styled from 'styled-components';
import DynamicIcon from '../DynamicIcon';
import {Button} from '../ButtonStyles';

const DocumentPanel = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 40px;
  width: 800px;
  border-radius: 12px;
  background-color: rgba(80, 80, 80, 0.5);
  border-color: rgba(201, 76, 76, 0.3);
`;
const Actions = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  margin-left: auto;
`;
const Icon = styled(DynamicIcon)`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const CONSENT_MUTATION = gql`
    mutation {
        consentToGeneralTerms {
            id
            acceptedTerms
        }
    }
`;

const EULA = () => {
  // ---------- Set browser tab title and meta description --------------------

  document.title = 'EULA | Cway®';
  document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway EULA page');

  // ------------------------------------------------------------------------------------------

  const [consentToGeneralTerms, { loading, error }] = useMutation(CONSENT_MUTATION);

  // TODO: URLs for download and print EULA file???
  const eula = '/eula';

  if (error) return <div>Something went wrong...try again later</div>;

  return (
    <DocumentPanel>
      <EulaText />
      <Actions>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a href={eula} download><Icon tag={DownloadIcon}/></a>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a href={eula} download><Icon tag={PrintIcon}/></a>
        <ButtonWrapper>
          <Button onClick={() => consentToGeneralTerms()}>
            Accept
          </Button>
        </ButtonWrapper>
      </Actions>

      {loading && <LoadingIndicator fullscreen />}
    </DocumentPanel>
  );
};
export default EULA;
