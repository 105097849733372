import React, {useEffect} from 'react';
import {LoginInfo, OrganisationMembership} from '@collabra/cway-frontend-common/types/__generated__/graphql';
import {FormPanel} from './Form/StyledComponents';
import styled from 'styled-components';
import {useReactiveVar} from "@apollo/client";
import {organisationVar} from "../graphql/ReactiveVars";
import OrganisationBadgeCount from "./OrganisationBadges";

const Scrollable = styled.div`
    width: 100%;
    height: 61vh;
    overflow-y: auto;
    border-radius: 5px;
    background-color: white;
`;
const Header = styled.h1`
    color: whitesmoke;
    font-size: larger;
`;
const List = styled.div`
    display: flex;
    flex-direction: column;
`;
const ListItem = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px 15px;
    cursor: pointer;
    color: ${({theme}) => theme.colors.standardTextColor};

    &:hover {
        background-color: #efefef;
    }
`;
const Text = styled.span`
    color: #5BB5FD;
`;
const OrganisationLogo = styled.img`
    width: 50px;
    height: 50px;
`;
const OrganisationName = styled.span`
    margin-top: 0.2em; // fix vertical aligning for Special Elite font
`;

interface Props {
    loginInfo: LoginInfo,
    onSelectOrganisation: ({organisationId, name, permissionGroupId}: OrganisationMembership) => void,
}

const SelectOrganisation = ({loginInfo, onSelectOrganisation}: Props) => {
    // ---------- Set browser tab title and meta description --------------------
    const preselectedOrganisation = useReactiveVar(organisationVar);

    document.title = 'Select company | Cway®';
    document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway select company page');

    // ------------------------------------------------------------------------------------------

    const organisations = loginInfo?.organisationMemberships;

    useEffect(() => {
        if (preselectedOrganisation) {
            const org = organisations.find((org) => org.organisationId === preselectedOrganisation);
            if (org) {
                onSelectOrganisation(org);
            }
        }
    }, []);

    if (!organisations) return <div/>;

    if (organisations.length === 0) {
        return (
            <FormPanel $hasBackground>
                <Header>No organisations</Header>
                <Text>
                    Your user is not added to any organisation. Ask your administrator to add the username
                    `{loginInfo && loginInfo.user.username}` to your organisation
                </Text>
            </FormPanel>
        );
    }

    return (
        <FormPanel $width={500} $hasBackground>
            <Header>Select the organisation to work with</Header>

            <Scrollable>
                <List>
                    {organisations.map((org) => (
                        <ListItem key={org.organisationId} onClick={() => onSelectOrganisation(org)}>
                            <OrganisationLogo src={`/file/organisation/${org.organisationId}`}
                                              alt={`${org.name} logo`}/>
                            <OrganisationName>{org.name}</OrganisationName>
                            <OrganisationBadgeCount organisationId={org.organisationId}/>
                        </ListItem>
                    ))}
                </List>
            </Scrollable>
        </FormPanel>
    );
};

export default SelectOrganisation;
