import React, {useEffect, useRef, useState} from 'react';
import {isValidEmail} from '@collabra/cway-frontend-common/utils';
import {useCookies} from 'react-cookie';
import {FormPanel, TextInput} from '../Form/StyledComponents';
import Api from '../../Api';
// import Socials from '../Socials';
import styled from 'styled-components';
import {Button, ButtonLink} from '../ButtonStyles';
import Checkbox from '../Form/Checkbox';
// import {CwayLogoIcon} from '../Icons';
// import BubbleBackground from '../../img/backgrounds/cloud.svg';
import {useReactiveVar} from "@apollo/client";
import {organisationVar} from "../../graphql/ReactiveVars";
import GartnerLinks from '../GartnerLinks';
import CwayLogo, {AnimationType} from '../CwayLogo';

const Block = styled.div<{ $isLower?: boolean }>`
    width: 100%;
    padding: 20px;
    background-color: rgba(28,28,28);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin-top: ${({$isLower}) => !$isLower ? '0px' : '20px'};;
`;
const InputsWrapper = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
`;
const CheckboxStyled = styled(Checkbox)`
    align-self: flex-start;
    font-size: small;
`;
const ForgotHint = styled.div`
    position: absolute;
    right: calc(100% + 20px);
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    padding: 8px 8px 5px;
    font-size: small;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    color: #212529;

    &:after {
        content: "";
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        border: 10px solid transparent;
        border-left-color: white;
    }
`;
const Link = styled.a`
    align-self: flex-start;

    &, &:hover {
        color: white;
        font-size: small;
    }
`;
const LinkWrapper = styled.div<{ $highlighted: boolean }>`
    align-self: flex-start;
    position: relative;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({$highlighted}) => $highlighted ? 'white' : 'transparent'};
    padding: 6px 6px 3px;
    display: inline-flex;
    align-items: center;
    line-height: 1;
`;
const Text = styled.p`
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
`;

// const LogoBox = styled.div`
//     display: inline-flex;
//     flex-grow: 1;
// `;
// const CwayLogoIconStyled = styled(CwayLogoIcon)`
//     width: 70px;
//     height: 70px;
// `;
// const CopyrightMarker = styled.span`
//     font-size: 14px;
//     position: relative;
//     color: white;
//     margin-top: auto;
//     left: -7px;
// `;
const H1 = styled.h1`
    font-size: 32px;
`;
const H2 = styled.h2`
    font-size: 18px;
`;

const GartnerLinksContainer = styled.div`
    position: absolute;
    bottom: 50px;
    right: calc(100% - 20px);
`;

interface Props {
    username: string | null,
    forgot: boolean,
    error: string | null,
    loading: boolean,
    onLogin: (usernameLocal: string, password: string) => void,
    onForgotPassword: (usernameLocal: string) => void,
    highlightForgotPwdButton: boolean,
}

const LoginBox = ({username, forgot, error, loading, onLogin, onForgotPassword, highlightForgotPwdButton}: Props) => {
    const preselectedOrganisation = useReactiveVar(organisationVar);

    // ---------- Set browser tab title and meta description --------------------

    document.title = 'Login | Cway®';
    document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway login page');

    // ---------- Storage in cookies --------------------

    const [cookies, setCookie, removeCookie] = useCookies(['user', 'rememberMe']);
    const [rememberMe, setRememberMe] = useState(!!cookies.rememberMe);

    // ---------- Form inputs --------------------

    // eslint-disable-next-line no-nested-ternary
    const [usernameLocal, setUsernameLocal] = useState(username != null ? username : (cookies.user != null ? cookies.user : ''));
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [forgotPassword, setForgotPassword] = useState(forgot);
    useEffect(() => setForgotPassword(forgot), [forgot]);

    const forgotPasswordLink = <Link href="#" onClick={() => setForgotPassword(true)}>Darn, I forgot my password! Help
        me get a new one!</Link>;

    // Since dev password is "pwd" for all users - reduce requirement of password length for development environment
    const minPwdLength = process.env.NODE_ENV === 'development' ? 3 : 5;
    const formValid = isValidEmail(usernameLocal) && (password.length >= minPwdLength || !showPassword);

    const usernameInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);

    // ---------- Form submission --------------------

    const handleLoginButton = () => {
        if (showPassword) onLogin(usernameLocal, password);
        else {
            Api.tryToLoginWithoutPassword(usernameLocal, preselectedOrganisation, () => {
                setShowPassword(true);
            });
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const e = event.nativeEvent;
        if (formValid && (e.charCode === 13 || e.code === 'Enter' || e.code === 'NumpadEnter')) handleLoginButton();
    };

    // ---------- Focusing inputs --------------------

    // Focus username field on mount
    useEffect(() => usernameInput.current?.focus(), []);
    // Focus password field on display password
    useEffect(() => {
        if (usernameInput.current && (usernameLocal !== '') && showPassword) {
            passwordInput.current?.focus();
        }
    }, [showPassword]);

    // ---------- Cookies control --------------------

    useEffect(() => {
        if (!rememberMe) {
            removeCookie('user');
            removeCookie('rememberMe');
        } else {
            setCookie('user', usernameLocal);
            setCookie('rememberMe', true);
        }
    }, [rememberMe, usernameLocal, setCookie, removeCookie]);

    // ------------------------------------------------------------------------------------------

    return (
        <>
            <FormPanel $width={550}>
                <Block>
                    <CwayLogo color="white" size={73} animation={loading ? AnimationType.ROTATE : undefined}/>
                    {/*<LogoBox><CwayLogoIconStyled/><CopyrightMarker>®</CopyrightMarker></LogoBox>*/}
                    <H1>{forgotPassword ? 'RESET YOUR PASSWORD' : 'WELCOME TO CWAY®'}</H1>
                    {!forgotPassword && <H2>Your hub for seamless collaboration.<br/> Step into a world of limitless possibilities.</H2>}

                    <InputsWrapper>
                        <TextInput
                            type="email"
                            ref={usernameInput}
                            placeholder="Username"
                            onChange={(e) => setUsernameLocal(e.target.value.toLowerCase())}
                            onKeyDown={(e) => handleKeyPress(e)}
                            value={usernameLocal}
                            disabled={loading}
                        />

                        {forgotPassword && (
                            <Text>
                                So you forgot your password?<br/>
                                That&apos;s ok everyone can forget!
                                Fill in your e-mail address and hit the &apos;Create new password&apos; button
                                and we will send you a link to create a new one in a jiffy.
                            </Text>
                        )}

                        {!forgotPassword && (
                            <>
                                {showPassword && (
                                    <TextInput
                                        type="password"
                                        ref={passwordInput}
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                        disabled={loading}
                                    />
                                )}

                                <CheckboxStyled label="Remember me" checked={rememberMe}
                                                onChange={(e) => setRememberMe(e.target.checked)}/>

                                {!highlightForgotPwdButton ? forgotPasswordLink : (
                                    <LinkWrapper $highlighted>
                                        {forgotPasswordLink}
                                        {highlightForgotPwdButton &&
                                            <ForgotHint>If you forgot your password, you can generate a new
                                                one</ForgotHint>}
                                    </LinkWrapper>
                                )}

                                <Button disabled={!formValid} onClick={handleLoginButton}>NEXT</Button>
                            </>
                        )}

                        {forgotPassword && (
                            <>
                                {/*<Button $secondary onClick={() => setForgotPassword(false)}>Back to login page</Button>*/}
                                <Button
                                  onClick={() => onForgotPassword(usernameLocal)}
                                  disabled={!isValidEmail(usernameLocal) || loading}
                                >
                                    Create new password
                                </Button>
                            </>
                        )}
                    </InputsWrapper>
                </Block>

                {!forgotPassword && (
                  <Block $isLower>
                    <H2>ARE YOU HERE VISITING?</H2>
                    <Text>How about getting your own Cway® account?</Text>
                    <Text>Join a dynamic environment that empowers businesses to connect, collaborate, and thrive. Step
                        into a world of limitless possibilities, where your vision comes to life and your brand reaches
                        new heights.</Text>
                    <Text>Cway® handles the entire packaging design process and approval, as well as asset lifecycle
                        management.</Text>
                    <Text>All in one place.</Text>
                    <ButtonLink href="https://www.cwaysoftware.com/free-trial" target="_blank" rel="noopener noreferrer">
                        GET A FREE ACCOUNT
                    </ButtonLink>
                    </Block>
                )}

                {/* {error && <Error errorMessage={error} />} */}

                {!forgotPassword && (
                  <GartnerLinksContainer>
                      <GartnerLinks/>
                  </GartnerLinksContainer>
                )}
            </FormPanel>
        </>
    );
};

export default LoginBox;
