import React from 'react';
import {FormPanel} from './Form/StyledComponents';
import Link from './Link';
import {NapIcon} from './Icons';
import styled from 'styled-components';

const NapIconStyled = styled(NapIcon)`
  width: 200px;
  height: 200px;
`;
const Header = styled.h1`
  font-family: 'Londrina Solid', cursive;
  font-size: 38px;
  color: #374759;
  margin-bottom: 20px;
`;
const Text = styled.p`
  width: 100%;
  text-align: center;
`;

const ServerDown = () => {
  // ---------- Set browser tab title and meta description --------------------

  document.title = 'Server is not responding | Cway®';
  document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway server is not responding page');

  // ---------- Link to support email --------------------

  const email = 'support@cway.se';
  const subject = 'Cway® is sleeping';
  const body = 'Cway® has been sleeping a long time now. Could you please try and wake here up for me?';
  const supportEmailLink = <Link href={`mailto:${email}?subject=${subject}&body=${body}`}>support team</Link>;

  // ------------------------------------------------------------------------------------------

  return (
    <FormPanel $hasBackground>
      <NapIconStyled/>
      <Header>I&apos;m taking a nap</Header>
      <Text>Elderly people and computer<br/>systems need to nap sometimes to<br/>feel invigorated.</Text>
      <Text>Soon I&apos;ll wake up again, full of energy!</Text>
      <Text>
        If you feel like you&apos;ve waited<br/>long enough and that I should<br/>have woken up by now, don&apos;t<br/>
        hesitate to give my {supportEmailLink}<br/>a nudge!
      </Text>
    </FormPanel>
  );
};

export default ServerDown;
