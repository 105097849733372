/**
 * Export icons as React components with ability to pass props (for example, to add style)
 */

import React from 'react';
import {FontAwesomeIcon as FA, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import {faCheck, faDownload, faInfoCircle, faPrint} from '@fortawesome/pro-regular-svg-icons';
import {faLinkedinIn, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {ReactComponent as CwayLogoSvg} from '../../img/icons/cway-logo.svg';
import {ReactComponent as CwayLogoTextDownSvg} from '../../img/icons/cway-logo-text-down.svg';
import {ReactComponent as CwayLogoTextRightSvg} from '../../img/icons/cway-logo-text-right.svg';
import {ReactComponent as CwayLogoTextRightBlackSvg} from '../../img/icons/cway-logo-text-right-black.svg';
import {ReactComponent as NapSvg} from '../../img/icons/pillow.svg';
import {ReactComponent as HeartSvg} from '../../img/icons/heart.svg';

// ==================== Custom SVG icons ====================

const svgIconStyles = {height: '1em', margin: '0.2em'};

export const CwayLogoIcon = (props: JSX.IntrinsicAttributes) => <CwayLogoSvg {...svgIconStyles} {...props}/>;
export const CwayLogoTextDownIcon = (props: JSX.IntrinsicAttributes) => <CwayLogoTextDownSvg {...svgIconStyles} {...props}/>;
export const CwayLogoTextRightIcon = (props: JSX.IntrinsicAttributes) => <CwayLogoTextRightSvg {...svgIconStyles} {...props}/>;
export const CwayLogoTextRightBlackIcon = (props: JSX.IntrinsicAttributes) => <CwayLogoTextRightBlackSvg {...svgIconStyles} {...props}/>;
export const NapIcon = (props: JSX.IntrinsicAttributes) => <NapSvg {...svgIconStyles} {...props}/>;
export const HeartIcon = (props: JSX.IntrinsicAttributes) => <HeartSvg {...svgIconStyles} {...props}/>;

// ==================== FontAwesome icons ====================

// Remove 'icon' prop from type because it's provided to every FA element
type IconPropsType = JSX.IntrinsicAttributes & Omit<FontAwesomeIconProps, 'icon'>;

export const CheckIcon = (props: IconPropsType) => <FA {...props} icon={faCheck}/>;
export const InfoCircleIconO = (props: IconPropsType) => <FA {...props} icon={faInfoCircle}/>;
export const DownloadIcon = (props: IconPropsType) => <FA {...props} icon={faDownload}/>;
export const PrintIcon = (props: IconPropsType) => <FA {...props} icon={faPrint}/>;
export const LinkedInIcon = (props: IconPropsType) => <FA {...props} icon={faLinkedinIn}/>;
export const InstagramIcon = (props: IconPropsType) => <FA {...props} icon={faInstagram}/>;
export const YoutubeIcon = (props: IconPropsType) => <FA {...props} icon={faYoutube}/>;
