import React from 'react';
import LoadingIndicator from './LoadingIndicator';
import {Button} from './ButtonStyles';
import {FormPanel} from './Form/StyledComponents';
import Error from './Error';
import styled from 'styled-components';
import {/*CwayLogoIcon, */CheckIcon} from './Icons';
import CwayLogo, {AnimationType} from './CwayLogo';

const Block = styled.div<{ $isLower?: boolean }>`
    width: 100%;
    padding: 20px;
    background-color: rgba(28,28,28);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: ${({$isLower}) => !$isLower ? '0px' : '20px'};;
`;
// const LogoBox = styled.div`
//     display: inline-flex;
//     flex-grow: 1;
// `;
// const CwayLogoIconStyled = styled(CwayLogoIcon)`
//     width: 70px;
//     height: 70px;
// `;
// const CopyrightMarker = styled.span`
//     font-size: 14px;
//     position: relative;
//     color: white;
//     margin-top: auto;
//     left: -7px;
// `;
const H1 = styled.h1`
    font-size: 32px;
`;
const Text = styled.div`
    text-align: center;
    color: white;
`;
const CheckIconWrapper = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid white;
    padding: 10px;
`;
const CheckIconStyled = styled(CheckIcon)`
    width: 100%;
    height: 100%;
`;

interface Props {
  error: string | null,
  loading: boolean,
  goToLogin: () => void,
}

const PasswordRequested = ({ error, loading, goToLogin }: Props) => (
  <FormPanel $width={550}>
    <Block>
      <CwayLogo color="white" size={73} animation={loading ? AnimationType.ROTATE : undefined}/>
      {/*<LogoBox><CwayLogoIconStyled/><CopyrightMarker>®</CopyrightMarker></LogoBox>*/}
      <H1>PASSWORD RESET</H1>
      <Text>Link successfully sent!<br/>Check your email and use the link to change the password.</Text>

      <CheckIconWrapper>
        <CheckIconStyled />
      </CheckIconWrapper>

      <Button onClick={goToLogin}>
        Back to login page
      </Button>
    </Block>


      {error && <Error errorMessage={error} />}
    {loading && <LoadingIndicator fullscreen />}
  </FormPanel>
);

export default PasswordRequested;
