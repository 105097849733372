import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './fonts.css';
import { CookiesProvider } from 'react-cookie';
import ReactGA from 'react-ga';
import { ApolloProvider } from '@apollo/client';
import ErrorBoundary from './components/ErrorBoundary';
import App from './App';
import {ThemeProvider} from 'styled-components';
import ModernTheme from './Themes/Modern';
import GlobalStyle from './Themes/globalStyle';
import ChatWidget from './components/HubSpot/ChatWidget';

// GraphQL client
import ApolloClient from './graphql/Apollo';

// Setup Google Analytics
if (process.env.NODE_ENV === 'development') {
  console.log('Initializing Google Analytics for development');
  ReactGA.initialize('UA - 5519460 - 5');
} else {
  console.log('Initializing Google Analytics for production');
  ReactGA.initialize('UA-5519460-4');
}
ReactGA.pageview(window.location.pathname + window.location.search);

const root = createRoot(document.getElementById('root')!);

root.render(
    <ApolloProvider client={ApolloClient}>
      <ThemeProvider theme={ModernTheme}>
        <GlobalStyle/>

        <ErrorBoundary>
          <CookiesProvider>
            <BrowserRouter basename="/signon">
              <App />
              {/* Chat widget should not unmount so place it on the highest level */}
              <ChatWidget/>
            </BrowserRouter>
          </CookiesProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </ApolloProvider>,
);
