/* eslint max-len: 0 */
import React from 'react';
import styled from 'styled-components';

// Child components
// import Button from './Button';

const textLeftIndent = 30;
const DocumentPanel = styled.div`
  position: absolute;
  //top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 40px;
  width: 800px;
  border-radius: 12px;
  background-color: rgba(80, 80, 80, 0.5);
  border-color: rgba(201, 76, 76, 0.3);
`;
const TextContainer = styled.div`
  background-color: white;
  padding: 35px;
  font-size: 12px;
  
  & ol {
    margin-bottom: 10px;
    padding: 0;
    counter-reset: item;
  }
  & li {
    position: relative;
    display: block;
    margin-bottom: 5px;

    &:before {
      position: absolute;
      left: -${textLeftIndent}px;
      content: counters(item, ".") " ";
      counter-increment: item;
    }
  }
  
  & > ol > li {
    margin-left: ${textLeftIndent}px;
    font-weight: bold;
    font-size: 13px;
  }
  & > ol > li > ol, & > ol > li > p {
    font-weight: normal;
    font-size: 12px;
  }
  & > ol > li > ol > li > ol {
    padding-left: ${textLeftIndent}px;
    counter-reset: itemLetter;
  }
  & > ol > li > ol > li > ol > li {
    list-style: none;
    &:before {
      content: counter(itemLetter, lower-latin) ") ";
      counter-increment: itemLetter;
    }
  }
`;
const Header = styled.h1`
  font-size: 16px;
  font-weight: bolder;
`;

// TODO: Remove this when the page is ready
const DebugHighlight = styled.span`
  background-color: yellow;
`;

// const useStyles = makeStyles({
//   actions: {
//     width: '100%',
//     padding: '10px 0',
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
//   buttonWrapper: {
//     marginLeft: 'auto',
//   },
//   button: {
//     '&, &:hover': {
//       padding: '10px 15px',
//       backgroundColor: '#28a745',
//       color: 'white',
//     },
//   },
// }, { name: 'TermsAndConditions' });

const TermsAndConditions = () => {
  // ---------- Set browser tab title and meta description --------------------

  document.title = 'Terms and conditions | Cway®';
  document.querySelector('meta[name="description"]')?.setAttribute('content', 'Cway terms and conditions page');

  // ------------------------------------------------------------------------------------------

  return (
    <DocumentPanel>
      <TextContainer>
        <Header>Terms and Conditions for Curveball AB’s Cway® Services</Header>

        <ol>
          <li>Introduction
            <ol>
              <li>These terms and conditions are an integral part of the Cway® Services agreement (“Agreement”) between Curveball AB, a Swedish limited liability company with Swedish corporate reg no. 556756-9537 (“Supplier”) and its counterparty (“Customer”).</li>
              <li>The Agreement may have been entered into digitally on the Suppliers website or by a separate agreement document. If a separate agreement document has been entered into, then any conflicting terms contained in such document shall have precedence over the terms contained herein.</li>
            </ol>
          </li>

          <li>Background and agreement
            <ol>
              <li>Customer wishes, in accordance with the terms and conditions of the Agreement, to purchase the Service from the Supplier.</li>
              <li>The scope and terms of the provisioning of the Service are set out in the Agreement and its appendices including the appendices listed below, which constitute an integral part of the Agreement. If the documents contain conflicting information, this document takes precedence over the other appendices, unless the circumstances obviously dictate otherwise.</li>
              <p>
                <u>Appendices:</u><br />
                Appendix Service Specification <a target="_blank" rel="noreferrer" href="https://cway.se/features">https://cway.se/features</a> (“Specification”)<br />
                Appendix Price and Payment Terms <a target="_blank" rel="noreferrer" href="https://cway.se/pricing">https://cway.se/pricing</a> (“Price and Payment”)<br />
                Appendix Service Levels <DebugHighlight>[insert URL]</DebugHighlight> (“SLA“)<br />
                Appendix Data Processing Term <DebugHighlight>[insert URL]</DebugHighlight> (“Data Processing Appendix”)
              </p>
            </ol>
          </li>

          <li>Nature and Scope of the Service
            <ol>
              <li>Through this Agreement, the Supplier undertakes to provide service regarding media lifecycle management (hereinafter referred to as the “Service”) commencing the agreed Start Date.</li>
              <li>The Service comprises the services described in the Specification as made available on the Supplier’s website at <a target="_blank" rel="noreferrer" href="https://cway.se/features">https://cway.se/features</a> to the extent and in accordance with the terms and conditions set forth in this Agreement.</li>
              <li>In the event of any discrepancy between the documents included in the Agreement, the terms and conditions of this Agreement shall take precedence over appendices attached thereto.</li>
              <li>The Supplier shall be entitled, without prior notice to the Customer, to make such modifications to the Services as typically do not impede the use of the Service. If such changes to the Service materially reduce its functionality, the Supplier will inform the Customer thereof via email. By continuing to access or use the Service after changes become effective, the Customer will be considered to have accepted such changes. If the Customer does not agree to the changes, the Customer may terminate the Service within 30 days of receiving notice of the change. Also, the Supplier may further from time-to-time modify Price and Payments and SLA. If such modification is to the disadvantage to the Customer, then the Supplier will inform the Customer thereof via email or by way of notifications within the system/Service. By continuing to access or use the Service after changes become effective, the Customer will be considered to have accepted such changes. If the Customer does not agree to the changes, then the Customer may terminate the Service within 30 days of receiving notice of the change.</li>
            </ol>
          </li>

          <li>Performance of the Service
            <ol>
              <li>The Supplier shall perform all parts of the Service in a professional manner in accordance with the provisions of this Agreement and with the skill and care which the Customer has reason to expect from a well-reputed company in the industry.</li>
              <li>For performance of the Service, the Supplier shall retain qualified personnel suitable for the purpose.</li>
            </ol>
          </li>

          <li>Supplier’s Responsibilities
            <ol>
              <li>The Supplier shall deliver the Service to the Customer as set out in this Agreement and its appendices and any modifications thereto (see clause 3.4).</li>
              <li>The Supplier shall provide the Service with the best of its ability and shall not be responsible for deficiencies in the Service Level, unless the Supplier has acted negligently.</li>
              <li>The Supplier will use, at a minimum, industry standard technical and organizational security measures to transfer, store, and process Customer data. The Customer agrees that the Supplier may transfer, store, and process Customer Data in locations other than Customer&apos;s country. &quot;Customer Data&quot; means Stored Data and Account Data. &quot;Stored Data&quot; means the files and structured data submitted to the Services by the Customer, the Supplier or End Users. &quot;Account Data&quot; means the account and contact information submitted to the Services by Customer or End Users.</li>
              <li>The Supplier’s liability for third party products and services (including without limitation subcontractor services such as Amazon Web Services) in the Service is limited to, using the best of its ability, to defend the Customer and its rights against the third-party supplier. Supplier shall not be responsible for deficiencies in third party products or services, unless the Supplier has acted negligently.</li>
              <li>The Supplier is only responsible for the communication between the Supplier and the access point where the Supplier’s network connects to the internet. Thus, the Supplier shall have no other liability for the connections over the internet.</li>
            </ol>
          </li>

          <li>Customer’s Responsibilities
            <ol>
              <li>The Customer shall use the Service in accordance with the Service Specifications and manuals provided by the Supplier. The Customer shall not use the Service for other’s purposes but shall only use the Service for the Customer’s own purposes.</li>
              <li>The Customer shall provide data for the work performed by the Supplier at the start of the Service, Customer shall review documents and issue decisions and otherwise provide the information necessary for the Supplier to perform its commitments in accordance with the Agreement.</li>
              <li>The Customer shall be responsible for the communication between the Customer and the access point where the Supplier’s network connects to the internet and shall secure that the Customer holds the equipment and software required when using the Service.</li>
              <li>The Customer shall ensure that (i) the Customer’s data is free from virus, Trojans, worms or other malicious software or code, (ii) the Customer data is in the agreed format, and (iii) the Customer’s data otherwise cannot harm or adversely affect the Supplier’s system or Service.</li>
              <li>The Customer is responsible for that all login credentials, security methods and other information provided by the Supplier to access the Service, are handled with confidentiality. The Customer shall immediately notify the Supplier in the event an unauthorised person has accessed such information.</li>
              <li>The Customer is responsible for use of the Services by the users of the Customer’s Services account (“End Users”). The Customer and its End Users may only use the Services in compliance with the Acceptable Use Policy available for download on Supplier’s website at <DebugHighlight>[Insert URL.]</DebugHighlight></li>
              <li>The Customer will obtain from its End Users any consents necessary to allow Administrators (as defined below) to engage in the activities described in this document and to allow the Supplier to provide access to the Services to such End Users. The Customer will comply with laws and regulations applicable to Customer&apos;s and its End User’s use of the Services, if any.</li>
              <li>The Customer may specify certain End Users as &quot;Administrators&quot;. Administrators will have the ability to add further Administrators and non-administrator users. Any such users will be able to freely share Stored Data with external persons or entities.</li>
              <li>The Customer shall prevent unauthorized use of the Services by its End Users and terminate any unauthorized use of or access to the Services.</li>
              <li>The Customer will not (i) sell, resell, or lease the Services; or (ii) reverse engineer the Services, nor attempt nor assist anyone else to do so.</li>
            </ol>
          </li>

          <li>Warranty by the Customer and indemnification
            <ol>
              <li>The Customer warrants that it holds all required rights to the materials (including but not limited to artwork) and information provided by the Customer or on behalf of the Customer to the Supplier for the Supplier’s provision of Services.</li>
              <li>The Customer shall hold harmless and indemnify the Supplier for any third-party claims related to infringement of intellectual property rights by the Supplier when providing Services on or with materials provided as set out in the immediately preceding paragraph.</li>
            </ol>
          </li>

          <li>Commencement of Services
            <ol>
              <li>The Supplier is responsible for the availability of the Service to the Customer from the effective date of the Agreement.</li>
              <li>The Supplier may at its own discretion allow the Customer to use certain services without charge which may be specified in an order or may be as otherwise determined by the Supplier in its sole discretion during a certain period of time (“Trial Term”).</li>
            </ol>
          </li>

          <li>Subcontractors
            <p>The Supplier may appoint subcontractors for performance of the Services and for any obligation under the Agreement.</p>
          </li>

          <li>Fees and Payment Terms
            <ol>
              <li>Customer shall, for its use of the Service after the Trial Term (if applicable), pay a monthly Service Fee to Supplier as set out in Payment Terms <DebugHighlight>[Insert URL]</DebugHighlight>.</li>
              <li>The Service Fee shall be paid in advance by credit card as provided for on the Supplier’s website or by invoice if agreed upon separately.</li>
              <li>Late payment shall carry interest according to the Swedish Interest Act (1975:635) on the outstanding amount until full payment has been made. In addition, the Supplier has the right to, without prior notice, cease Customer’s access to the software until full payment has been made or terminate the Agreement with immediate effect.</li>
              <li>All fees and charges are set out exclusive of value added tax and other additional taxes and charges.</li>
              <li>All recurring Service Fees (e.g. monthly or annually) are subject to annual increases, commencing on the one-year anniversary date of the date of this Agreement, in an amount equal to the percentage increase by the Labour Cost Index (LCI) as published quarterly by Statistics Sweden (Statistiska Centralbyrån, <a target="_blank" rel="noreferrer" href="https://www.scb.se">www.scb.se</a>). The last published index value since such one-year anniversary or since the date of the last fee increase, as applicable, and such index value that was published one year prior to the last published index value, shall be used to calculate the increase.</li>
              <li>If the Supplier intends to increase recurring Service Fees in addition to what is set forth in clause 9.5, them Supplier must give notice of such increase to Customer no later than one (1) month prior to when the new fee shall take effect. If the Customer does not accept the new Service Fee, then the Customer will be entitled to terminate the Agreement as per the same date as the new Service Fee shall take effect.</li>
            </ol>
          </li>

          <li>Specific Terms and Conditions
            <ol>
              <li>The Supplier is entitled to consider any use of the Service, with the Customer’s log in credentials, as an authorised use by the Customer, regardless of whether such use incur costs to the Customer, unless and until the Customer notifies the Supplier’s customer support, in writing, about suspected unauthorised use and ensure that the user credentials are blocked or altered.</li>
              <li>It is the responsibility of the Customer to immediately notify the Supplier&apos;s support or customer service if the Customer suspects that this may be the case and ensure that the Customer&apos;s user information is blocked or changed.</li>
              <li>The Supplier shall use reasonable efforts to ensure that the Service is available 24 hours a day, all year. The Supplier may, however, at any time and without further notice, cut off or limit the access to the Service if urgent maintenance is required or in order to protect the Service from unauthorised attacks. Nevertheless, the Supplier shall, to the extent possible, inform the Customer when these interruptions may occur. The Customer is not entitled to compensation for such limitation or lack of access. The Supplier is not liable for any lack of access due to internet failure or communication failure due to the use of private or public networks.</li>
            </ol>
          </li>

          <li>Service Limitations
            <ol>
              <li>In those cases where the Service is or the Service is exposed to external attacks or otherwise harm or risk, the Supplier is entitled to cut off or limit access to the Service. Customer shall be notified about the limitation of access to Service and when the Service is expected to be available again.</li>
              <li>The Supplier shall, in case the Customer violates the Agreement or applicable law and if this may cause harm to the Supplier, be entitled to stop the Customer from using the Service until the violation has been ceased and the damage is restored. </li>
            </ol>
          </li>

          <li>Intellectual property rights
            <ol>
              <li>Ownership of and all Intellectual Property Rights in the Service vest exclusively in Supplier and, or as the case may be, its suppliers or licensors.</li>
              <li>Nothing in the Agreement or otherwise between the Parties shall be construed as transferring or assigning the title or ownership of any intellectual property rights in the Service or the Software from Supplier to the Customer. Nevertheless, the data and result created by the Service as a result of Customer’s use shall thereof, without restrictions, be owned by the Customer with an unrestricted right, subject to no limitations or constraints, and without informing or consulting with the Supplier, to (i) make changes to the Result, and (ii) lease or assign the Result. The provisions of preceding sentence shall only apply to rights to any third party materials provided that the Supplier has the right to assign such rights to the Customer.  The Supplier is entitled to use Customer’s data and result only to the extent required for the Supplier to fulfil its obligations in accordance with the Agreement. However, the Supplier has the right, without restriction, to use the information about processes’ features, performance, capacity and other statistics or similar details based on the Service’s processing of Customer’s data and results. Any rights to software and fonts are not included in the Results unless specifically agreed in writing for each particular delivery.</li>
              <li>The Supplier warrants that the performance of the Service and the Software in accordance with the Agreement does not infringe in third party’s Intellectual Property Rights. In the event of a claim alleging that use of the Service infringes the rights of, or has caused harm to, a third party’s Intellectual Property Rights, the Supplier shall indemnify and hold Customer harmless, provided that the Customer: (i) promptly gives written notice of the claim to Supplier; (ii) gives Supplier sole control of the defence and settlement of the claim (iii) has not agreed, compromised or settled such claim or otherwise affected Supplier’s ability to defend or negotiate the claim, and (iv) the Customer reasonably provides the Supplier with all available information and assistance.</li>
              <li>In the event that the requirements under clause above have occurred, or if the Supplier believes that risk of such claims may exist, the Supplier has the right, in regard to the Service, to (i) procure the right for Customer to continue using the Service, (ii) make changes to the Service to no longer be considered infringing or, if neither (i) or (ii) can be achieved in terms Supplier deems reasonable (iii) terminate the Agreement with immediate effect and cut off Customer’s access to the Service. The Customer is not entitled to compensation for such alteration. However, the Customer is upon termination pursuant to (iii) above entitled to receive return payment of part of the paid Service Fee by the amount corresponding to the duration of the current Agreement period where the Service cannot be used in relation to the time for which payment has been made.</li>
            </ol>
          </li>

          <li>Third Party Software
            <ol>
              <li>In the event the Service contains software from third party (“Third Party Software”) such software shall be provided and used in accordance with the terms and conditions set out for respective Third Party Software. A list of all such Third Party Software included in the Software shall be specified in the Specification. The applicable terms and conditions, or wherever these can be obtained, shall be specified in the Specification.</li>
            </ol>
          </li>

          <li>Processing of Personal Data
            <ol>
              <li>The Customer shall ensure that the processing of personal data within the scope of performance of the Service is in conformity with current law.</li>
              <li>The Supplier shall process personal data on behalf of the Customer according to the Customer’s instructions in accordance with the terms in the Data Processing Appendix.</li>
            </ol>
          </li>

          <li>Statistics and Anonymized Data
            <ol>
              <li>Supplier shall have the right to anonymise any and all data used in the system or the Service. The anonymisation procedure shall be sufficient to ensure that no data can be converted back to personal data.</li>
              <li>Supplier shall own the right and title to all such anonymised data without limitation in time and use for any purpose.</li>
              <li>Supplier shall have the right to use any and all data in the Service for statistical purposes.</li>
            </ol>
          </li>

          <li>Liability and Limitation of Liability
            <ol>
              <li>The Supplier’s responsibilities for damage due to errors or delay are limited in accordance with the following. The Supplier will not be liable for indirect damages, including but not limited to loss of profit, loss of data, liability to third party, and costs related to production, unless caused by gross negligent or intent. Supplier shall under no circumstances be liable for loss of data due caused by a third party or a subcontractor, provided that Supplier has used reasonable care and diligence when appointing subcontractors.</li>
              <li>The maximum aggregate liability for any loss or damage, whether direct or indirect, shall in respect of any one or more events or series of connected events be limited to fifty (50) per cent of the fees concerning the specific part of Service in question at the time of the loss or damage. <i>(<u>Example:</u> If there has occurred an error relating to a specific artwork, the maximum aggregate liability for such error will be limited to fifty per cent of the fee for the specific artwork.)</i></li>
              <li>Under no circumstances will the Supplier be liable for any loss or damage relating to deliverables that the Customer has approved.</li>
              <li>The Customer will not be entitled to compensation unless claims are made in writing to the Supplier within three (3) months from the day the suffering party learns about or should reasonably have learned about the alleged damaging circumstances.</li>
              <li>Any claim from the Customer shall be supported by proven costs, such as invoices from the print supplier.</li>
              <li>Notwithstanding the above, the Supplier’s total liability during the Trial Term shall be limited to a maximum amount of SEK 5,000.</li>
            </ol>
          </li>

          <li>Force Majeure
            <ol>
              <li>A Party shall be excused from performance of its obligations under the Agreement, if performance is prevented due to circumstances outside the Party’s control (“Force Majeure”). Performance shall be resumed as soon as the force majeure event ceases to affect performance.</li>
              <li>Force Majeure events shall include war, war action, terrorist attacks, pandemics and epidemics, orders by public authorities, new or amended legislation, industrial action or other circumstances outside the affected Party’s control.</li>
              <li>A Party whose performance is affected by an event of Force Majeure shall notify the other Party in writing thereof without delay.</li>
              <li>Notwithstanding the foregoing, a Party shall be entitled to void the Agreement if the performance by the other Party of a material obligation hereunder has been prevented for more than two months.</li>
            </ol>
          </li>

          <li>Confidentiality
            <ol>
              <li>Each party agrees that during the term and 12 months thereafter not to reveal confidential information to third parties, which the party obtains from the other party, or which arises during performance of the Service.</li>
              <li>Confidential information refers in this Agreement to any item of information – technical, commercial or of any other nature – regardless of whether or not such information has been documented, with the exception of:
                <ol>
                  <li>information, which is generally known, or which becomes a matter of general knowledge in a manner other than through the breach of the provisions of the Agreement;</li>
                  <li>information, which a party can prove that he had possessed before he received it from the other party; and</li>
                  <li>information, which a party received or will receive from a third party when the party does not have a duty of secrecy to such third party.</li>
                </ol>
              </li>
              <li>In cases referred to by c), above, a party is not however entitled to reveal to third parties that the same information has been received from the other party pursuant to this Agreement.</li>
              <li>Each party agrees to ensure that its employees, consultants and board members do not disclose confidential information to third parties.</li>
              <li>Each party undertakes to adhere to the other party&apos;s instructions regarding data security as well as instructions for handling of personal data.</li>
              <li>The parties shall not without compelling reason reveal
                <ol>
                  <li>the contents of the Agreement or any arbitration award related to the Agreement</li>
                  <li>any information regarding negotiations or arbitration or mediation proceedings related to the Agreement.</li>
                </ol>
              </li>
              <li>The obligations under a)–b) above shall survive the termination of the Agreement.</li>
              <li>The provisions, set out in this section, apply unless the Parties have signed a separate confidentiality agreement that involves a more extensive privacy commitment between the Parties.</li>
              <li>Notwithstanding the confidentiality obligations above, the Supplier may make public that the Customer is a customer to the Supplier and uses the Services.</li>
            </ol>
          </li>

          <li>Term
            <p>The Agreement shall come into force on the date when the Service is available for the Customer at the Access point and shall thereafter continue until further notice, provided that specified Service Fees have been paid by Customer.</p>
          </li>

          <li>Termination
            <ol>
              <li>If the Customer has not paid the Service Fees upon expiration of the Trial Term, if applicable, then the agreement shall automatically terminate upon the expiration of the Trial Term.</li>
              <li>The Customer may at any time, by notifying the Supplier in writing, terminate the Agreement to end the use of the Service. If the Service Fee is ongoing, the Agreement shall be considered terminated if the Customer, notwithstanding a reminder, does not pay the Service Fee for the next period.</li>
              <li>The Supplier is entitled to terminate the Agreement if the Supplier itself shall cease providing the Service. Such termination shall take effect no earlier than three months from the written notice of the Supplier.</li>
              <li>Each Party has the right to terminate the Agreement with immediate effect if:
                <ul>
                  <li>the other Party has committed a material breach and does not remedy the same no later than thirty (30) days from notification requiring the defaulting Party to remedy the same; or</li>
                  <li>the other Party suspends its payments, decides on, whether voluntary or involuntary, liquidation, applies to reorganize the company or bankruptcy (or if another part applies for the Party’s bankruptcy) or otherwise reasonably can be assumed to be insolvent.</li>
                </ul>
              </li>
            </ol>
          </li>

          <li>Modification of terms
            <p>The Supplier may revise the terms for the Services from time to time and the most current version of the terms will always be available in at the Services website at <DebugHighlight>[insert URL]</DebugHighlight>. If a revision, in the Supplier’s sole discretion, is material, the Supplier will notify the Customer in writing by email. By continuing to access or use the Services after revisions become effective, the Customer agrees to be bound by the revised terms. If the Customer does not agree to the revised terms, the Customer may terminate the Services within 30 days of receiving notice of the change.</p>
          </li>

          <li>Exit Service
            <ol>
              <li>Upon termination of the Agreement, the Customer and the End Users will no longer have access to the Services, except for downloading Stored Data. Six (6) months after termination, the Supplier will have no obligation to store or retain Customer Data, including Stored Data, and may at its own discretion erase such data.</li>
              <li>The Customer may, no later than 30 days before the Agreement expires, notify the Supplier that the Customer wishes assistance transferring the Customer data. The Supplier is entitled to charge the Customer in accordance with the applicable price list for data converting and transfer.</li>
            </ol>
          </li>

          <li>Assignment
            <ol>
              <li>Neither party may not in wholly or partly assign or pledge its rights and/or obligations under the Agreement to any third party without the prior written approval of the other party.</li>
              <li>Notwithstanding the above, the Supplier may assign or pledge its rights to receive payments under the Agreement. </li>
            </ol>
          </li>

          <li>Notices and Language
            <ol>
              <li>Any notice and other communication concerning application of the Agreement to be given by a party under the Agreement (hereinafter called a Notice) shall be (i) if the Customer is a Swedish entity, in the Swedish language, or (ii) if the Customer is non-Swedish entity, in the English language and deemed to be valid and effective if personally served on the other party or sent by registered prepaid airmail or e-mail to the addresses provided by the Parties when entering the Agreement or as later amended.</li>
              <li>A Notice shall be deemed to have been given:
                <ol>
                  <li>in the case of personal service: at the time of service; </li>
                  <li>in the case of prepaid registered mail: at the latest five days after the date of mailing; or</li>
                  <li>in the case of e-mail: on the date the e-mail is sent, provided receipt is duly confirmed by the other party.</li>
                </ol>
              </li>
              <li>Changes of address are to be notified as set out in this provision.</li>
            </ol>
          </li>

          <li>Headings
            <p>The division of these terms and conditions into separate articles and the insertion of headings shall not affect the interpretation of the Agreement.</p>
          </li>

          <li>Amendments
            <p>Only those amendments and additions to this contract that are made in writing and signed by the parties, physically or electronically, are valid.</p>
          </li>

          <li>Waiver
            <ol>
              <li>No consent or waiver, express or implied, by either party of any breach or default of the other party in performing its obligations under the Agreement shall be deemed or construed to be a consent or waiver of any other breach or default by the other party of the same or any other obligation hereunder. Any failure by one party to complain of any act or failure to act of the other party or to declare that other party in default shall not constitute a waiver by the first party of its rights under the Agreement. No waiver of any rights under the Agreement shall be effective unless in writing and signed by the party purporting to give the same.</li>
            </ol>
          </li>

          <li>Entire Agreement
            <ol>
              <li>The Agreement and its appendices constitute the entire agreement between the parties on all matters to which the Agreement relates. Any particular matter that has not been expressly covered in the Agreement shall be resolved in accordance with the principles on which the Agreement is founded.</li>
              <li>The contents of this Agreement and its appendices shall supersede all previous written or oral commitments and undertakings.</li>
            </ol>
          </li>

          <li>Severability
            <ol>
              <li>If any provision of the Agreement or part thereof shall to any extent be or become invalid or unenforceable, the parties shall agree upon any necessary and reasonable adjustment of the Agreement in order to secure the vital interests of the parties and the main objectives prevailing at the time of execution of the Agreement. Failing an agreement between the parties on adjustments of the Agreement, such adjustments shall be made by arbitrators in accordance with the provisions of the arbitration clause in the Agreement.</li>
            </ol>
          </li>

          <li>Governing law
            <ol>
              <li>This Agreement shall be construed in accordance with and be governed by the substantive laws of Sweden.</li>
            </ol>
          </li>

          <li>Dispute Resolution
            <ol>
              <li>Any dispute, controversy or claim arising out of or in connection with this contract, or the breach, termination or invalidity thereof, shall be finally settled by arbitration in accordance with the Rules of the Arbitration Institute of the Stockholm Chamber of Commerce for expedited arbitration procedure.</li>
              <li>The arbitration proceedings shall be conducted in English and take place in Gothenburg, Sweden, where the award shall be made. The Supplier may also initiate proceedings before the Enforcement Agency and the general courts of Sweden with the District Court of Gothenburg as court of first instance for claims relating to non-payment of Service Fees.</li>
            </ol>
          </li>
        </ol>
      </TextContainer>

      {/* <div className={classes.actions}> */}
      {/*   <div className={classes.buttonWrapper}> */}
      {/*     <Button onClick={() => { window.location.href = '/signon/register'; }}> */}
      {/*       Back to onboard */}
      {/*     </Button> */}
      {/*   </div> */}
      {/* </div> */}
    </DocumentPanel>
  );
};

export default TermsAndConditions;
