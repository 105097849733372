import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FormPanel} from './Form/StyledComponents';
import Error from './Error';
import styled from 'styled-components';
import {useCookies} from "react-cookie";

const Header = styled.h1`
  margin-bottom: 30px;
  font-size: x-large;
`;

interface Props {
}

const HubSpotLandingPage = ({}: Props) => {
    const search = useLocation().search;
    const [{main_token}, setCookie, removeCookie] = useCookies(['main_token']);
    const params = new URLSearchParams(search);
    const [loginFailed, setLoginFailed] = useState(false);

    const checkAndRedirect = () => {
        const redirect_url = params.get('redirect_url');
        if (main_token) {
            location.href = 'https://www.cway.se/_hcms/mem/jwt?jwt=' + main_token + '&redirect_url=' + redirect_url;
        }else{
            setLoginFailed(true);
        }
    };

    useEffect(() => {
        checkAndRedirect();
    }, [main_token]);

    useEffect(() => {
        if (loginFailed) { // @ts-ignore
            setTimeout(() => window.location.href = process.env.REACT_APP_CWAY_HOST, 15000)
        }
    }, [loginFailed])

    if (loginFailed) {
        return (
            <FormPanel $hasBackground>
                <Header>Login failed!</Header>
                <Error errorMessage="Login to single signon provider failed. Try logging into Cway® and then go to the tickets site again."/>
            </FormPanel>
        );
    }

    return (
        <FormPanel $hasBackground>
            <Header>Logging in. Please wait...</Header>
            <FontAwesomeIcon icon={faSpinner} spin/>
        </FormPanel>
    );
};

export default HubSpotLandingPage;
