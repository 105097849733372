import React from 'react';
import styled from 'styled-components';
import {CwayLogoTextRightBlackIcon} from '../Icons';

const Root = styled.div`
    background-color: white;
    padding: 40px;
`;
const Title = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: 50px;
`;
const Logo = styled(CwayLogoTextRightBlackIcon)`
    height: 250px;
    margin: 40px 0;
`;
const TitleHeader = styled.h1`
    font-size: 32px;
    font-weight: normal;
`;
const TitleSubheader = styled.p`
    max-width: 460px;
    font-size: 14px;
    text-align: center;
    margin-bottom: 100px;
`;
const TitleTextRight = styled.p`
    width: 100%;
    font-size: 12px;
    text-align: right;
`;
const ParagraphHeader = styled.h2`
    margin: 15px 0 10px;
    font-size: 18px;
    font-weight: normal;
`;
const Text = styled.p`
    margin-bottom: 5px;
    font-size: 12px;
`;
const Ol = styled.ol`
    font-size: 18px;
`;
const Ul = styled.ul`
    padding-left: 20px;
    font-size: 12px;
    list-style-type: disc;
`;
const Li = styled.li`
    font-weight: normal;
    margin-bottom: 5px;
`;

const EulaText = () => (
  <Root>
    <Title>
      <Logo/>
      <TitleHeader>End User License Agreement</TitleHeader>
      <TitleSubheader>By signing, you Cwayve goodbye to chaos and hello to seamless branding—our tools keep your projects in line, so you stay artworking smarter, not harder!</TitleSubheader>
      <TitleTextRight>Version 5.1 – Effective 1st Jan 2025<br/>CWAY 556756-9537</TitleTextRight>
    </Title>

    <Text>Introduction</Text>
    <Text>This End User License Agreement (“EULA”) constitutes a legally binding agreement between you (the “End User”) and Cway AB (the “Supplier”).
      It governs your access to and use of the Cway Services.</Text>
    <Text>By using the Cway Services, you confirm your acceptance of this EULA. If you do not agree to the terms, you must discontinue use immediately.</Text>
    <Ol>
      <Li>
        <ParagraphHeader>License Grant & Restrictions</ParagraphHeader>
        <Text>Cway AB grants End Users a <b>limited, non-exclusive, non-transferable, revocable</b> right to access
          and use Cway Services in accordance with this EULA and the applicable Customer agreement.
          This license does not grant ownership of the software or related intellectual property.</Text>
        <Text>End Users may not:</Text>
        <Ul>
          <Li>Copy, modify, distribute, or create derivative works based on Cway Services.</Li>
          <Li>Reverse engineer, decompile, or attempt to extract source code.</Li>
          <Li>Use the Services in violation of any laws or regulations.</Li>
        </Ul>
      </Li>

      <Li>
        <ParagraphHeader>Intellectual Property Rights</ParagraphHeader>
        <Text>All rights, title, and interest in Cway Services and its underlying technology, trademarks, and copyrights remain the exclusive
          property of Cway AB. No rights are granted except as expressly stated in this EULA.</Text>
      </Li>

      <Li>
        <ParagraphHeader>Access and System Requirements</ParagraphHeader>
        <Text>Cway Services are cloud-based and require an internet connection. For optimal performance, users should use a modern web browser
          (Google Chrome, Safari, or Microsoft Edge, latest two versions) and a minimum screen resolution of 1024x768px.
          Full-screen mode is recommended for best functionality.</Text>
      </Li>

      <Li>
        <ParagraphHeader>Data Processing & Security</ParagraphHeader>
        <Text>The Supplier implements industry-standard security measures to protect Customer Data, including encryption and access controls.
          By using Cway Services, you authorize the Supplier to transfer, store, and process Customer Data outside your country if required.</Text>
      </Li>

      <Li>
        <ParagraphHeader>Personal Data Processing:</ParagraphHeader>
        <Ul>
          <Li>The Supplier processes End User personal data (name, email) for service fulfillment.</Li>
          <Li>Personal data may be shared with authorized system users (e.g., project members, administrators, developers when necessary).</Li>
          <Li>Data is retained for the contract term, plus <b>ten years and two months</b> for approved work.</Li>
          <Li>If transferred outside the EU, safeguards under <b>Article 46 of GDPR</b> will apply.</Li>
        </Ul>
        <Text>Requests for data access, rectification, or erasure must be directed to the Customer.
          If necessary, the Supplier can assist via <b>personaldata@cway.se</b>.</Text>
        <Ul>
          <Li>Complaints may be lodged with the <b>Swedish Data Protection Authority</b> (datainspektionen@datainspektionen.se).</Li>
        </Ul>
      </Li>

      <Li>
        <ParagraphHeader>Service Updates</ParagraphHeader>
        <Text>Cway Services are continuously improved. If an update significantly reduces functionality, Customers will be notified via email.
          Continued use after changes take effect constitutes acceptance.</Text>
      </Li>

      <Li>
        <ParagraphHeader>End User Responsibilities</ParagraphHeader>
        <Text>End Users must:</Text>
        <Ul>
          <Li>Use the Services lawfully and in compliance with the Acceptable Use Policy.</Li>
          <Li>Maintain account confidentiality and security.</Li>
          <Li>Not sell, resell, or reverse engineer Cway Services.</Li>
        </Ul>
      </Li>

      <Li>
        <ParagraphHeader>Suspension & Termination</ParagraphHeader>
        <Text>The Supplier may suspend an account if:</Text>
        <Ul>
          <Li>The End User violates this EULA.</Li>
          <Li>The End User’s activity poses a risk to the system or other users.</Li>
        </Ul>
        <Text>Suspensions due to <b>Security Emergencies</b> (e.g., unauthorized access, service disruptions) may occur without prior notice.</Text>
      </Li>

      <Li>
        <ParagraphHeader>Service Availability & Support</ParagraphHeader>
        <Text>The Supplier makes commercially reasonable efforts to ensure service uptime. Remedies for downtime are defined in the Customer’s contract.</Text>
      </Li>

      <Li>
        <ParagraphHeader>Data Collection & Service Improvement</ParagraphHeader>
        <Text>Cway collects usage data (e.g., interactions, device logs) to optimize service performance. Data may be used for improvements without time limitations.</Text>
      </Li>

      <Li>
        <ParagraphHeader>Termination & Data Retention</ParagraphHeader>
        <Text>Upon termination:</Text>
        <Ul>
          <Li>End Users lose access but can download Stored Data.</Li>
          <Li>Data is retained for <b>six (6) months</b> unless an earlier deletion request is made by contacting <b>support@cway.se</b>.</Li>
          <Li>After six months, data is permanently deleted unless required by law.</Li>
        </Ul>
      </Li>

      <Li>
        <ParagraphHeader>Liability & Indemnification</ParagraphHeader>
        <Ul>
          <Li>Cway AB’s total liability shall not exceed the <b>fees paid by the Customer in the last 12 months</b>.</Li>
          <Li>Cway AB is <b>not liable</b> for indirect, incidental, or consequential damages, including data loss, business interruption, or lost profits.</Li>
          <Li>End Users indemnify the Supplier against third-party claims arising from misuse of the Services.</Li>
        </Ul>
      </Li>

      <Li>
        <ParagraphHeader>Modifications to Terms</ParagraphHeader>
        <Text>The Supplier may update this EULA periodically. Material changes will be communicated via email. Continued use of the Services implies acceptance.
          Customers may terminate their contract within <b>30 days</b> of notice if they do not accept the changes.</Text>
      </Li>

      <Li>
        <ParagraphHeader>Acceptable Use Policy</ParagraphHeader>
        <Text>End Users must not:</Text>
        <Ul>
          <Li>Bypass security features.</Li>
          <Li>Access unauthorized areas of the system.</Li>
          <Li>Disrupt service operation (e.g., spamming, hacking, or overloading the system).</Li>
          <Li>Share misleading, fraudulent, or unlawful content.</Li>
          <Li>Store, publish, or distribute offensive material.</Li>
        </Ul>
        <Text>Violations may result in account suspension or termination.</Text>
      </Li>
    </Ol>

    <Text>For questions, contact <b>support@cway.se</b>.</Text>
  </Root>
);

export default EulaText;
